import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import About from "../components/About"
import AllProjects from "../components/AllProjects"
import Hero from "../components/Hero"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Syawal Jasira Personal and Portfolio Website" />
      <header>
        <Hero />
        <About />
      </header>
      <main>
        <AllProjects data={data} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllProjectsQuery {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { header: { eq: "project" } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            techA
            techB
            techC
            url
            img {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          id
        }
      }
    }
  }
`

export default IndexPage
