import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"

import {
  SectionHeader,
  SectionCard,
  SectionTitle,
  SectionParagraph,
} from "./SectionArticle"
import { ButtonTech, TechIcon } from "./Tech"
import Button from "./Buttons"
import SelectedProject from "./SelectedProject"

const AllProjects = ({ data }) => {
  const [selectedTechs, setSelectedTechs] = React.useState(null)
  const projects = data.allMdx.edges

  const handleClick = tech => {
    setSelectedTechs(tech)
  }

  return (
    <div id="projects" className="py-8 mx-auto text-center">
      <article className="w-auto text-center mx-auto mt-32">
        <SectionHeader>All Projects</SectionHeader>
        <div className="w-10/12 xl:w-6/12 mx-auto lg:mb-6 xl:mb-8">
          <SectionParagraph className="mx-auto">
            Below is all the projects where it has been done. Select the
            technology icon to see what projects use that technology.
          </SectionParagraph>
        </div>
        <div className="pb-8 lg:pb-12 w-10/12 sm:w-9/12 md:w-8/12 lg:w-7/12 mx-auto flex flex-wrap justify-center">
          <ButtonTech
            tech="nextjs"
            title="Next.js"
            _callback={handleClick}
            techQuery="nextjsQuery"
          />
          <ButtonTech
            tech="gatsbyjs"
            title="Gatsby.js"
            _callback={handleClick}
            techQuery="gatsbyjsQuery"
          />
          <ButtonTech
            tech="react"
            title="React"
            _callback={handleClick}
            techQuery="reactQuery"
          />
          <ButtonTech
            tech="styledcomponents"
            title="Styled Components"
            _callback={handleClick}
            techQuery="none"
          />
          <ButtonTech
            tech="firebase"
            title="Firebase"
            _callback={handleClick}
            techQuery="none"
          />
          <ButtonTech
            tech="typescript"
            title="Typescript"
            _callback={handleClick}
            techQuery="none"
          />
          <ButtonTech
            tech="sass"
            title="Sass"
            _callback={handleClick}
            techQuery="sassQuery"
          />
          <ButtonTech
            tech="bootstrap"
            title="Bootstrap"
            _callback={handleClick}
            techQuery="bootstrapQuery"
          />
          <ButtonTech
            tech="mongoDB"
            title="Mongo DB"
            _callback={handleClick}
            techQuery="mongoDBQuery"
          />
          <ButtonTech
            tech="tailwindCSS"
            title="Tailwind CSS"
            _callback={handleClick}
            techQuery="tailwindQuery"
          />
          <ButtonTech
            tech="redux"
            title="Redux"
            _callback={handleClick}
            techQuery="none"
          />
        </div>
        <div className="block md:flex md:flex-wrap justify-center">
          {selectedTechs != null ? (
            <SelectedProject tech={selectedTechs} />
          ) : (
            projects.map(project => {
              const { id } = project.node
              const { title, techA, techB, techC, url } =
                project.node.frontmatter
              const image = getImage(project.node.frontmatter.img)
              return (
                <div
                  className={classNames(
                    "my-12 w-11/12 mx-auto md:w-6/12 lg:w-5/12 lg:mx-10",
                    techA,
                    techB,
                    techC
                  )}
                  key={id}
                >
                  <SectionCard>
                    <SectionTitle className="text-center xl:text-2xl xl:py-6">
                      {title}
                    </SectionTitle>
                    <div className="hover:scale-105 duration-200">
                      <GatsbyImage image={image} alt={"Pictures of " + title} />
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="px-2 py-5 lg:py-8">
                        <Button url={url}>Project live</Button>
                      </div>
                      <div className="flex px-2 py-5 lg:py-8">
                        <TechIcon
                          tech={techA}
                          className="px-1 py-2 xl:px-2 xl:py-4"
                        />
                        <TechIcon
                          tech={techB}
                          className="px-1 py-2 xl:px-2 xl:py-4"
                        />
                        <TechIcon
                          tech={techC}
                          className="px-1 py-2 xl:px-2 xl:py-4"
                        />
                      </div>
                    </div>
                  </SectionCard>
                </div>
              )
            })
          )}
        </div>
      </article>
    </div>
  )
}

export default AllProjects
