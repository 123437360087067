import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const Hero = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query BgQuery {
      placeholderImage: file(relativePath: { eq: "bg-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1440, quality: 100)
        }
      }
    }
  `)

  const pluginImage = getImage(placeholderImage)

  return (
    <BgImage
      image={pluginImage}
      style={{
        backgroundSize: "cover",
        minHeight: 210,
      }}
    >
      <div className="py-6 md:py-16 lg:py-28 xl:py-36">
        <h1 className="uppercase text-2xl md:text-3xl lg:text-5xl lg:tracking-wide xl:text-6xl  text-white text-center pt-12 pb-4 md:pt-6 lg:pt-8 lg:pb-6 xl:pb-10">
          Syawal Jasira
        </h1>
        <h2 className="text-md md:text-lg lg:text-xl xl:text-2xl text-white text-center md:pb-8 lg:pb-8">
          Front-End Developer
        </h2>
      </div>
    </BgImage>
  )
}

export default Hero
