import * as React from "react"

export default function Button({ url, className, children }) {
  return (
    <button
      className={
        "rounded-lg text-xs sm:text-lg text-green-700 border-2 border-green-700 py-1 px-5 hover:bg-green-700 hover:text-white duration-300 " +
        className
      }
    >
      <a href={url} target="_blank">
        {children}
      </a>
    </button>
  )
}
