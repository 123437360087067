import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"

import { SectionCard, SectionTitle } from "./SectionArticle"
import { TechIcon } from "./Tech"
import Button from "./Buttons"

const SelectedProject = ({ tech }) => {
  const data = useStaticQuery(graphql`
    query TechQuery {
      reactQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techA: { eq: "react" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      sassQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techB: { eq: "sass" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      bootstrapQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techC: { eq: "bootstrap" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      tailwindQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: {
            header: { eq: "project" }
            techC: { eq: "tailwind CSS" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      gatsbyjsQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techA: { eq: "gatsbyjs" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      nextjsQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techA: { eq: "nextjs" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
      mongoDBQuery: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          frontmatter: { header: { eq: "project" }, techB: { eq: "mongoDB" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              techA
              techB
              techC
              url
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            id
          }
        }
      }
    }
  `)

  const techQuery = {
    reactQuery: data.reactQuery.edges,
    sassQuery: data.sassQuery.edges,
    bootstrapQuery: data.bootstrapQuery.edges,
    tailwindQuery: data.tailwindQuery.edges,
    gatsbyjsQuery: data.gatsbyjsQuery.edges,
    nextjsQuery: data.nextjsQuery.edges,
    mongoDBQuery: data.mongoDBQuery.edges,
    none: "none",
  }

  const pickedTechQuery = techQuery[tech]
  return (
    <>
      {" "}
      {pickedTechQuery === "none" ? (
        <div className="lg:text-xl">
          Sorry, there is no project with this technology.
        </div>
      ) : (
        pickedTechQuery.map(project => {
          const { id } = project.node
          const { title, techA, techB, techC, url } = project.node.frontmatter
          const image = getImage(project.node.frontmatter.img)

          return (
            <div
              className={classNames(
                "my-12 w-11/12 mx-auto md:w-6/12 lg:w-5/12 lg:mx-10",
                techA,
                techB,
                techC
              )}
              key={id}
            >
              <SectionCard key={id}>
                <SectionTitle className="text-center xl:text-2xl xl:py-6">
                  {title}
                </SectionTitle>
                <div className="hover:scale-105 duration-200">
                  <GatsbyImage image={image} alt={"Pictures of " + title} />
                </div>
                <div className="flex justify-between items-center">
                  <div className="px-2 py-5 lg:py-8">
                    <Button url={url}>Project live</Button>
                  </div>
                  <div className="flex px-2 py-5 lg:py-8">
                    <TechIcon
                      tech={techA}
                      className="px-1 py-2 xl:px-2 xl:py-4"
                    />
                    <TechIcon
                      tech={techB}
                      className="px-1 py-2 xl:px-2 xl:py-4"
                    />
                    <TechIcon
                      tech={techC}
                      className="px-1 py-2 xl:px-2 xl:py-4"
                    />
                  </div>
                </div>
              </SectionCard>
            </div>
          )
        })
      )}
    </>
  )
}

export default SelectedProject
