import * as React from "react"

import {
  SectionHeader,
  SectionTitle,
  SectionParagraph,
  SectionContent,
} from "./SectionArticle"

const About = () => {
  return (
    <div className="relative">
      <article className="w-10/12 md:w-8/12 bg-light mx-auto -mt-8 md:-mt-10 md:py-4 lg:py-6 rounded-lg shadow-card">
        <SectionHeader>About Me</SectionHeader>
        <SectionContent>
          <SectionTitle>Who Am I?</SectionTitle>
          <SectionParagraph>
            I'm a curious person who just found new ambition for his life.
            that's to love learn new things, especially in web development
            world. My world revolves around React and friends, that's the
            technology i work daily with and know best.{" "}
          </SectionParagraph>
        </SectionContent>
        <SectionContent>
          <SectionTitle>What I Do?</SectionTitle>
          <SectionParagraph>
            I have over a half years of experience developing web applications,
            building websites and actively experimenting with new things.
          </SectionParagraph>
        </SectionContent>
      </article>
    </div>
  )
}

export default About
